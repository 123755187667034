/* eslint-disable max-len */
import { gql, MutationResult, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { tacticFragment } from 'basics/graphql/fragments/tactic.fragments';
import { MutationReturnType } from 'basics/types/maya.types';
import { Tactic, TacticInput } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const updateApprovedTacticMutationCall = gql`
mutation updateApprovedTactic($id: String!, $updateTactic: TacticInput!, $cepId: String!, $isMajorUpdates: Boolean!, $sendToVeeva: Boolean!) {
  updateApprovedTactic(id: $id, updateTacticInput: $updateTactic, cepId: $cepId, isMajorUpdates: $isMajorUpdates, sendToVeeva: $sendToVeeva) {
    ${tacticFragment}
  }
}
`;

type ResultType = {
  updateApprovedTactic: Tactic;
};

type UseUpdateApprovedTacticMutation = [
  (
    id: string | null,
    tactic: Partial<TacticInput>,
    cepId: string,
    isMajorUpdates: boolean,
    sendToVeeva?: boolean,
  ) => Promise<MutationReturnType<Tactic>>,
  MutationResult<ResultType>,
];

export const useUpdateApprovedTacticMutation = (): UseUpdateApprovedTacticMutation => {
  const [updateApprovedTactic, state] = useMutation<ResultType>(updateApprovedTacticMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const flags = useFlags();

  const executeMutation = useCallback(async (id, tactic, cepId, isMajorUpdates, sendToVeeva = true) => {
    try {
      const { data } = await updateApprovedTactic({
        variables: {
          id,
          updateTactic: tactic,
          cepId,
          isMajorUpdates,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816 && sendToVeeva,
        },
      });

      return { data: data?.updateApprovedTactic || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, tactic);
        Logger.logError({
          error,
          customContext,
          transactionName: 'updateApprovedTactic.ts - useUpdateApprovedTacticMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [flags.mayaEnableVeevaBiogenlinc1816, updateApprovedTactic]);

  return [executeMutation, state];
};
