import { useFlags } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { mayaTacticPrintRoute } from 'basics/constants/routes.constants';
import { CepStatus, TacticMode, TacticStatus } from 'basics/enums/maya.enums';
import { useUpdateTacticMutation } from 'basics/graphql/mutations/updateTactic';
import useGetAllTacticsQuery from 'basics/graphql/queries/getAllTactics';
import useGetCepByIdLazyQuery from 'basics/graphql/queries/getCepByIdLazy';
import { CepStateType } from 'basics/types/maya.types';
import { errorToast, succesToast } from 'basics/utils/toast';
import { Tactic, Channel } from 'generated/maya.types';
import cepState from 'states/cep.states';
import tacticState from 'states/tactic.states';

const useTacticKanban = () => {
  const { t } = useTranslation();
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const cepId = cepStateValue.cep?._id;
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;

  const queryFilters = useMemo(
    () => ({
      cep: cepId,
      status: [TacticStatus.EMPTY, TacticStatus.DRAFT, TacticStatus.APPROVED],
    }),
    [cepId],
  );

  const { data, loading } = useGetAllTacticsQuery(undefined, queryFilters);
  const [updateTactic] = useUpdateTacticMutation();
  const { queryGetCepById } = useGetCepByIdLazyQuery();
  const flags = useFlags();

  useEffect(() => {
    if (!loading) {
      cepState.tactics = data.filter((tactic) => tactic.channel !== Channel.group);
      cepState.tacticGroups = data.filter((tactic) => tactic.channel === Channel.group);
    }
  }, [data, loading]);

  type TacticActionOrderGroup = {
    [actionOrder: string]: Tactic[];
  };

  const groupedTactics: TacticActionOrderGroup = {};
  cepStateValue.tactics.forEach((tactic) => {
    const actionOrder = tactic?.actionOrder;

    if (actionOrder) {
      if (groupedTactics[actionOrder] === undefined) {
        groupedTactics[actionOrder] = [tactic];
      } else {
        groupedTactics[actionOrder].push(tactic);
      }
    }
  });

  const updateTacticKanbanState = (tacticId: string, actionOrder: number) => {
    const clonedTactics = cloneDeep(cepStateValue.tactics);
    const targetTacticsIndex = clonedTactics.findIndex((tactic) => { return tactic._id === tacticId; });
    const targetTactic = clonedTactics.find((tactic) => { return tactic._id === tacticId; });
    if (targetTacticsIndex !== -1) {
      clonedTactics.splice(
        targetTacticsIndex,
        1,
        { ...targetTactic, actionOrder } as Tactic,
      );
      cepState.tactics = clonedTactics;
    }
  };

  const updateTacticActionOrder = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (parseInt(result.destination.droppableId, 10) === parseInt(result.source.droppableId, 10)) {
      return;
    }

    updateTacticKanbanState(result.draggableId, parseInt(result.destination.droppableId, 10));

    const tacticActionOrderUpdated = await updateTactic(
      result.draggableId,
      { actionOrder: parseInt(result.destination.droppableId, 10) },
      cepId as string,
      true,
    );

    if (tacticActionOrderUpdated) {
      const { data: customerEngagementPlanning } = await queryGetCepById({ variables: { id: cepStateValue.cep?._id } });
      if (cepState.cep) {
        cepState.cep.globalCepSyncStatus = customerEngagementPlanning.getCustomerEngagementPlanningById.globalCepSyncStatus;
      }

      succesToast(t('maya_action_update_tactic_action_order_success'));
    } else {
      errorToast(t('maya_action_update_tactic_action_order_error'));
      updateTacticKanbanState(result.draggableId, parseInt(result.source.droppableId, 10));
    }
  };

  const newTacticClick = () => {
    tacticState.mode = TacticMode.form;
  };

  const newElementButton = {
    newElementLabel: t('maya_list_new_element_label', { elementTitle: 'Tactic' }),
    newElementClick: newTacticClick,
    newElementDisabled: isCepApproved
    && (!flags.mayaBdcfcsd653ExtendEditingOfApprovedCep || !flags.mayaBdcfcsd1251EnableTacticCreationForApprovedCep),
  };

  const printViewButton = {
    printViewLabel: t('maya_tactic_print_label'),
    printViewClick: () => window.open(`${mayaTacticPrintRoute}?id=${cepId}`, '_blank'),
  };

  return {
    groupedTactics,
    newElementButton,
    printViewButton,
    updateTacticActionOrder,
    loading,
  };
};

export default useTacticKanban;
