import { YodaColors, YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

export const mainContainerStyle = {
  paddingRight: YodaSpacing.xLarge,
  paddingLeft: YodaSpacing.xLarge,
};

export const titleBoxStyle = {
  fontSize: YodaFontSize.xxxxLarge,
  color: YodaColors.gray5,
};

export const descriptionBoxStyle = {
  marginBottom: YodaSpacing.small,
  fontSize: YodaFontSize.xLarge,
  maxWidth: '60%',
  color: YodaColors.gray4,
};

export const imageBoxStyle = {
  marginX: 'auto',
  marginTop: YodaSpacing.xLarge,
  maxWidth: '70%',

  '@media (min-width: 1500px)': {
    maxWidth: '60%',
  },

  '@media (min-width: 1700px)': {
    maxWidth: '49%',
  },
};

