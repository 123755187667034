import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { TacticDisplayType, TacticMode } from 'basics/enums/maya.enums';
import { TacticStateType } from 'basics/types/maya.types';
import TacticKanban from 'components/CepForm/Steps/TacticsStep/TacticKanban/TacticKanban';
import TacticsList from 'components/CepForm/Steps/TacticsStep/TacticsList/TacticsList';
import useTacticsStep from 'components/CepForm/Steps/TacticsStep/TacticsStep.hook';
import { ClosingCrossStyle, TacticDialogStyle } from 'components/CepForm/Steps/TacticsStep/TacticsStep.styles';
import { TacticStepPropsType } from 'components/CepForm/Steps/TacticsStep/TacticStep.types';
import TacticForm from 'components/TacticForm/TacticForm';
import tacticState from 'states/tactic.states';
import Dialog from 'yoda-ui/components/Dialogs/Dialog';
import Icon, { Icons } from 'yoda-ui/components/Icons/Icon';

const TacticsStep: FC<TacticStepPropsType> = ({ isTemplate }) => {
  const tacticStateValue = useSnapshot(tacticState) as TacticStateType;
  const { cardSwitchConfig, displayType, handleTacticDialogClose } = useTacticsStep();
  const { t } = useTranslation();

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={ cardSwitchConfig.value }
        exclusive
        onChange={ cardSwitchConfig.onclick }
        fullWidth
        sx={ { marginBottom: '80px' } }
      >
        <ToggleButton value={ TacticDisplayType.list }>{ t('maya_tactic_toggle_list') }</ToggleButton>
        <ToggleButton value={ TacticDisplayType.kanban }>{ t('maya_tactic_toggle_kanban') }</ToggleButton>
      </ToggleButtonGroup>
      {
        tacticStateValue.mode === TacticMode.form
          ? <Dialog
            open={ tacticStateValue.mode === TacticMode.form }
            maxWidth="md"
            minWidth="50%"
            minHeight="350px"
            sx={ TacticDialogStyle }
          >
            <Icon
              name={ Icons.close }
              fontSize="medium"
              style={ ClosingCrossStyle }
              onClick={ handleTacticDialogClose }
            />
            <TacticForm isTemplate={ isTemplate }/>
          </Dialog>
          : displayType === TacticDisplayType.kanban
            ? <TacticKanban />
            : <TacticsList />
      }
    </>
  );
};

export default TacticsStep;
