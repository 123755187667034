/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

import { FC, memo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import useMiViewTacticList from './MiViewTacticList.hook';
import {
  mainWrapperStyle,
  targetListRowStyle,
  listWrapperStyle,
} from './MiViewTacticList.style';
import { TacticListProps, RowProps } from './MiViewTacticList.types';
import MiViewScreenListSkeleton from 'components/MiViewScreenListSkeleton/MiViewScreenListSkeleton';
import MiViewTacticListRow from 'components/MiViewTacticListRow/MiViewTacticListRow';
import MiViewTargetListRow from 'components/MiViewTargetListRow/MiViewTargetListRow';
import { Channel, Tactic } from 'generated/maya.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TacticRow: FC<RowProps> = memo(({ index, style, data }) => {
  const { getTacticsByParentTactic } = useMiViewTacticList();
  const { tactics, filteredTactics } = data;
  const currentTactic = filteredTactics[index];
  const childRecords = getTacticsByParentTactic(tactics, currentTactic);

  return (
    <MiViewTacticListRow
      id={ `${index}` }
      childRecords={ childRecords.length ? childRecords : null }
      tactic={ currentTactic }
      style={ style }>
    </MiViewTacticListRow>
  );
});

const MiViewTacticList: FC<TacticListProps> = ({ tactics, nonChildTactics, target }) => {
  const filteredTactics = tactics?.filter((tactic: Tactic) => {
    const childTactics = tactics.filter((childTactic) => childTactic.parentTactic?._id === tactic._id);
    return (!tactic.parentTactic && tactic.channel !== Channel.group)
            || (tactic.parentTactic && childTactics.length)
            || (tactic.channel === Channel.group && childTactics.length);
  });

  return (
    <>
      <div style={ mainWrapperStyle }>
        {
          target ? (
            <MiViewTargetListRow target={ target } tactics={ nonChildTactics } selectedTactic={ null } style={ targetListRowStyle }>
            </MiViewTargetListRow>
          ) : (
            <MiViewScreenListSkeleton listItemsToRender={ 1 } animated={ true } />
          )
        }
      </div>
      <div style={ listWrapperStyle }>
        <AutoSizer>
          {
            ({
              height,
              width,
            }: {
              height: string | number;
              width: string | number;
            }) => (
              <List
                itemData={ { tactics, filteredTactics } }
                height={ height }
                width={ width }
                itemSize={ 100 }
                itemCount={ filteredTactics.length }
                overscanCount={ 2 }
              >
                { TacticRow }
              </List>
            )
          }
        </AutoSizer>
      </div>
    </>
  );
};

export default MiViewTacticList;
