import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { CepSteps, TacticDisplayType, TacticMode } from 'basics/enums/maya.enums';
import { CepStateType } from 'basics/types/maya.types';
import useSelectTacticDesignConfig from 'components/CepForm/Steps/TacticsStep/TacticsStep.config';
import cepState from 'states/cep.states';
import dropMaterials from 'states/dropMaterials.states';
import tacticState from 'states/tactic.states';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useTacticsStep = () => {
  const [displayType, setdisplayType] = useState<TacticDisplayType>(TacticDisplayType.kanban);
  const { useWatchStep } = useYodaForm();
  const stepState = useWatchStep(CepSteps.tacticsStep);
  const cepStateValue = useSnapshot(cepState) as CepStateType;

  const { cardSwitchConfig } = useSelectTacticDesignConfig(displayType, setdisplayType);

  useEffect(() => {
    tacticState.cepId = cepStateValue.cep?._id || '';
    tacticState.tactic = null;
    tacticState.mode = TacticMode.list;
  }, [cepStateValue.cep?._id]);

  useEffect(() => {
    return function cleanup() {
      tacticState.cepId = '';
      tacticState.tactic = null;
      tacticState.mode = TacticMode.list;
      dropMaterials.dropMaterials = [];
    };
  }, []);

  const handleTacticDialogClose = () => {
    tacticState.tactic = null;
    tacticState.mode = TacticMode.list;
  };

  return {
    stepIsValid: stepState?.isValid || false,
    cardSwitchConfig,
    displayType,
    handleTacticDialogClose,
  };
};

export default useTacticsStep;
