import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import useTacticOptions from 'basics/options/tactic.options';
import { CepStateType, TacticStateType } from 'basics/types/maya.types';
import { TacticGroupOptionType } from 'basics/types/tacticGroups.types';
import { Channel as ChannelType } from 'generated/maya.types';
import cepState from 'states/cep.states';
import tacticState from 'states/tactic.states';

const useTacticGroups = () => {
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const tacticStateValue = useSnapshot(tacticState) as TacticStateType;
  const cepId = cepStateValue.cep?._id || null;
  const tacticId = tacticStateValue.tactic?._id ? [tacticStateValue.tactic?._id] : [];
  const tacticOptions = useTacticOptions(cepId, tacticId, [ChannelType.group]);
  const [tacticGroupsOptions, setTacticGroupsOptions] = useState<TacticGroupOptionType[]>(tacticOptions);
  const [showTacticGroup, setShowTacticGroup] = useState(false);

  useEffect(() => {
    if (cepStateValue.tacticGroups.length) {
      const updatedTacticGroupOptions: TacticGroupOptionType[] = cepStateValue.tacticGroups.map((group) => ({
        id: group._id,
        label: group.title,
      }));

      setTacticGroupsOptions(updatedTacticGroupOptions);
    }

    const allTactics = cepStateValue.tactics;
    const parentTacticIDs = allTactics.filter((tactic) => tactic.parentTactic?._id).map((tactic) => tactic.parentTactic?._id);
    const isParentTactics = Boolean(allTactics.find((tactic) => parentTacticIDs.includes(tactic._id)));

    setShowTacticGroup(!isParentTactics);
  }, [cepStateValue.tacticGroups, cepStateValue.tactics]);

  return {
    tacticGroupsOptions,
    showTacticGroup,
  };
};

export default useTacticGroups;
